
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import ProjectSurveyUrl from '@/pages/project/components/survey-url.vue'
import { ValidationMixin } from '@/mixins/validation-mixin';
import CreatedNav from "@/pages/project/created/created-nav.vue";
import { CreatedMixin } from '@/mixins/created-mixin'
import RegistrationStep from "@/pages/project/components/registration-step.vue";

@Component({
  components: {
    RegistrationStep,
    CreatedNav,
    ProjectSurveyUrl,
  }
})
export default class Step4 extends mixins(Vue, ValidationMixin, CreatedMixin) {
  agree = false;

  async created(): Promise<void> {
    await this.mixinsLoad();
  }
}
